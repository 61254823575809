<script lang="ts">
import { defineComponent } from 'vue'
import { useVueRecaptcha } from '~/composables/useRecaptcha';

interface IInstance extends ComponentPublicInstance {
  setPathFrom(from: string): void
}

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const instance = vm as IInstance
      instance.setPathFrom(from.path)
    })
  },
})
</script>

<script setup lang="ts">
 
import { ref, reactive, computed } from 'vue'
import { useStorage } from "@vueuse/core";
import { useForm } from 'vuestic-ui';
const { init, notify, close, closeAll } = useToast();
import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'

import { vMaska } from "maska/vue"

const route = useRoute();
const router = useRouter();

const sessionCookie = useCookie('session')
const nuxtApp = useNuxtApp();
let recaptcha;

onMounted(async () => {
    if (sessionCookie.value) {
        await navigateTo('/')
    } else {
        recaptcha = await useVueRecaptcha()
    }
});

 const emits = defineEmits(['closeLogin', 'otpSent'])
 const props = defineProps(['isMobile'])
 
 const cartData = useCartStore()
 const branchesData = useBranchStore()
 const sharedData = useSharedStore()

 const orderStore = useStorage("LastOrder", {});

///const phoneNumberField = ref('')
const phoneNumber = ref('')
const pin = ref('')
const countryCode = ref({ id: 'jo',  text: '🇯🇴 +962', value: '962' })
const numberValid = ref(false)

const showError = ref(false)

const countries = ref( [
        { id: 'jo', text: '🇯🇴 +962', value: '962' },
      ])

const showConfirmationModal = ref(false)

const loading = ref(false)

const step = ref(1)
const otpRes = ref({})

const from = ref('')
const showMessage = ref(false)
const wrongPin = ref(false)
const remainingAttempts = ref(9)

const timer = ref(30)
let interval = null


watch(step, (newVal) => {
    if (newVal == 2) {
        timer.value = 60
        setTimer()
    } else {
        clearInterval(interval)
    }
})

console.log('screen' + sharedData.screenIsMobile)

let fromQs = route.query["from"];
if (fromQs) {
    showMessage.value = true
    from.value = fromQs.valueOf()
    router.replace({ query: [] });
}

let pathFrom: string
const setPathFrom = (path: string) => {
  pathFrom = path
  console.log('vue-route::from::', pathFrom)
    step.value = 1
    phoneNumber.value = ''
    pin.value = ''
    otpRes.value = {}
}

defineExpose({ setPathFrom })

import { onBeforeRouteLeave } from 'vue-router';

onBeforeRouteLeave((to, from, next) => {
    clearInterval(interval)
    console.log('Leaving login page');
    next();
});


function setTimer() {
    interval = setInterval(() => {
            if (timer.value === 0) {
                clearInterval(interval)                
            } else {
                timer.value--
                console.log(timer)
            }             
        }, 1000)
}

function resendOTP() {
    timer.value = 60
    sendOTP()
    setTimer()
}


function isFieldValid(error) {
    numberValid.value = !error
}

const { isValid: profileInfoIsValid } = useForm('infoFormRef')
const infoForm = reactive({
  firstName: '',
  lastName: '',
  birthDate: null as Date | null,
})

async function sendOTP() {
    try {
        loading.value = true
        showError.value = false
        const res = await $fetch('/api/w/auth/login', {
            method: 'POST',
            headers: {
                'X-Brownie-Branch-ID': branchesData.SelectedBranchId,
                "X-Recaptcha-Token": await recaptcha('login'),
            },
            body: { number: phoneNumber.value, countryCode: countryCode.value.value },
        })
        //emits('otpSent', otpRes)
        otpRes.value = res
        step.value = 2
        loading.value = false
    } catch (error) {
        loading.value = false
        showError.value = true
        console.log(error)
    }
}


async function verifyOTP() {
    try {
        loading.value = true
        showError.value = false
        const otpVerificationRes = await $fetch('/api/w/auth/verify', {
            method: 'POST',
            headers: {
                'X-Brownie-Branch-ID': branchesData.SelectedBranchId,
                "X-Recaptcha-Token": await recaptcha('login'),
            },
            body: { pin: pin.value, pinId: otpRes.value['pinId'], number: phoneNumber.value, countryCode: countryCode.value.value },
            timeout: 10000
        })
        console.log(otpVerificationRes)
        
        if (otpVerificationRes.lastOrder) {
            orderStore.value = {"orderId" : otpVerificationRes.lastOrder.publicId, "ts": new Date(otpVerificationRes.lastOrder).getTime()}
            sharedData.ShowOrderTrackingBanner(true)
        } else  {
            orderStore.value = {}
        }
        loading.value = false
        
        refreshCookie('session')
        if (otpVerificationRes.newUser) { 
            step.value = 3
        } else {
            await goToMenu(false)
        }

        
    } catch (error) {
        loading.value = false
        showError.value = true
        // show error
        console.log(error)
        if (error.statusCode == 400) {
            wrongPin.value = true
            remainingAttempts.value = error.data.attemptsRemaining
            if (remainingAttempts.value < 1) {
                back()
            }
        }
    }
}

async function updateProfile(profile) {
    try {
        loading.value = true
        showError.value = false
        const profileRes = await $fetch('/api/w/profile', {
            method: 'PUT',
            headers: {
                'X-Brownie-Branch-ID': branchesData.SelectedBranchId
            },
            body: { profile: profile },
        })
        console.log(profileRes)
        loading.value = false
        
        await goToMenu(true)

        
    } catch (error) {
        loading.value = false
        showError.value = true
        // show error
        console.log('baaa' + error)
    }
    //await goToMenu()
}

async function goToMenu(newUser) {
    if (!showMessage.value) 
        await navigateTo({ path: '/' })
    else
        await navigateTo({ path: '/', query: { checkout: 1 } })
    
    if (newUser)
        notify(`Welcome!`);
    else
        notify(`Welcome back!`);
}
 
async function nextAction() {
    if (step.value == 1) {
        showConfirmationModal.value = !showConfirmationModal.value
    } else if (step.value == 2) {
        verifyOTP()
    } else {
        if (profileInfoIsValid) {
            await updateProfile(infoForm)
        } else {
            await goToMenu()
        }
    }
}
 

async function back() {
    if (step.value == 1) {
        await navigateTo({ path: '/' })
    } else {
        step.value = 1
    }
}

const canGoNext = computed(() => {
     if (step.value == 1) {
        return !numberValid.value && !loading
     } else {
        return !numberValid.value && !loading
     }
})

function backButtonTitle() {
    if (step.value == 1) {
        return 'Back'
    } else if (step.value == 2 ) {
         return 'Change Number'
    } else { 
        return 'Skip & Go to menu'
    }
}

function nextButtonTitle() {
    if (step.value == 1) {
        return 'Send'
    } else if (step.value == 2 ) {
         return 'Verify'
    } else { 
        return 'Save & Go to menu'
    }
}
 
 </script>
 

<template>
    <NuxtLayout>
    <div class="flex flex-col place-content-center place-items-center w-full pt-2 pb-2">
     <h4 class="va-h4">Login</h4>
 
        <span v-if="step == 1">
            {{ !showMessage ? 'Login with an OTP' : 'Login to complete your order' }}
        </span>
        <span v-else-if="step == 2">
            Enter the OTP sent to: {{ phoneNumber }}
        </span>
        <span v-else>
            Welcome!
        </span>
        <div class="flex xs:flex-col flex-row place-content-center place-items-center mb-10 my-5">
            

            <div class="text-md xs:mr-0 mr-2">{{ step == 1 ? 'Mobile Number:' : ''}}</div>
            
            <div class="mr-2 flex flex-row place-content-center place-items-center w-full" v-if="step == 1">
                <VaSelect        
                v-model="countryCode"
                :options="countries"
                style="max-width: 100px;"
                >
                
                <!-- <template #prependInner>
                    {{ countryCode.value }}
                </template> -->

                </VaSelect>
            

            <VaInput
                v-if="step == 1"
                class="input-phone"
                v-model="phoneNumber"
                inputmode="tel" 
                 placeholder="7 xxxx xxxx" v-maska="'7 #### ####'"
                :rules="[(v) => (v.startsWith('7') && v.length == 11) || ``]"  
                :returnRaw="true"
                :requiredMark="true"
                @update:error="isFieldValid"
                style="width: 150px;"
            />   
            </div>
            
            <div class="flex flex-col place-content-center place-items-center w-full" v-else-if="step == 2">
                <VaInput   
                    class="input-phone"
                    v-model="pin"
                    inputmode="numeric" 
                    placeholder="XXXXXX" v-maska="'######'"
                    :rules="[(v) => v.length == 6 || `OTP is 6-digits`]"  
                    :returnRaw="true"
                    :requiredMark="true"
                    @update:error="isFieldValid"
                /> 
                You can resend the code in {{ timer }}
                <VaButton preset="secondary" :disabled="timer > 0" @click="resendOTP">
                    Resend Code
                </VaButton>
            </div>  


            <div class="mb-5 flex flex-col place-content-center place-items-center w-full" v-else>
                <span class="text-md font-semibold">You can update the info later in the profile page</span>   

                 <VaForm ref="infoFormRef" class="flex flex-col items-baseline gap-2 my-3">
                    
                    <!-- :rules="[(value) => (value && value.length > 0 && value.length <= 50) || 'First name is required']" -->

                    <div>
                        <div>First Name</div>
                        <VaInput
                        v-model="infoForm.firstName"
                        data-maska="A" data-maska-tokens="A:[A-Za-z]:multiple"
                        />
                    </div>
                
                    <div>
                        <div>Last Name</div>
                        <VaInput
                        v-model="infoForm.lastName"        
                        />
                    </div>
                
                    <div>
                        <div>Birthdate (optional)</div> 
                        <Datepicker 
                            v-model="infoForm.birthDate"    
                            :enable-time-picker="false"
                            :month-change-on-scroll="false"
                            :auto-apply="true"
                            :max-date="new Date()"
                        />
                    </div>
                </VaForm>   

                </div>

                <span v-if="showError" class="my-2 text-md font-medium text-rose-800">
                   {{ wrongPin ? 'Wrong OTP please try again.' : 'Something went wrong 😔 Please try again' }}
                </span>
            
               
        </div>    

        <ClientOnly>
         <div class="flex flex-col border-t-2 border-l-2 border-r-2 border-b-2 border-dashed pt-2">
           
           <!-- <VaChip outline square class="mb-3 my-4 mx-2">
           v-maska="'+962 # #### ####'"
           Total:
           <span class="font-semibold mx-1 p-0.5">{{ cartData.Total }} JDs</span>
           </VaChip>          
           
              v-maska:argument.completed="numberValid"
                v-maska:argument.unmasked="phoneNumber"
           

                <template #default>
                    Back to Menu
                </template>
                <template #prepend="{ icon, iconAttributes }">
                    <Icon name="ic:round-arrow-back-ios-new" style="color: var(--va-primary);" size="20" class="mr-1" mode="svg"/>
                </template>
                </VaButton>

                <VaButton
                preset="primary"
                class="grow mx-2 mb-2"
                @click="stageItem()"
                icon-right="add"
                >
                <template #default>
                    Add to Cart
                </template>
                <template #append="{ icon, iconAttributes }">
                    <Icon name="ic:baseline-add" style="color: var(--va-primary);" size="21" class="ml-2" mode="svg"/>
                </template>
           
            -->
           
           <VaInnerLoading :loading="loading">
            <div class="flex flex-row">
           
             <VaButton
               preset="secondary"
               class="grow mx-2 mb-2"
               style="min-width: 150px;"
               @click="back"
               :icon="step <= 2 ? 'arrow_back_ios' : ''"
               :icon-right="step == 3 ? 'arrow_forward_ios' : ''"
               :disabled="loading"
               >
               <template #default>
                    {{ backButtonTitle() }}
                </template>
                <template v-if="step <= 2" #prepend="{ icon, iconAttributes }">
                    <Icon name="ic:round-arrow-back-ios-new" style="color: var(--va-primary);" size="20" class="mr-1 " mode="svg"/>
                </template>
                <template v-if="step == 3" #append="{ icon, iconAttributes }">
                    <Icon name="ic:round-arrow-forward-ios" style="color: var(--va-primary);" size="20" class="mr-1" mode="svg"/>
                </template>
               </VaButton>
               
            
                <VaButton
                preset="primary"
                class="grow mx-2 mb-2"
                style="min-width: 150px;"
                color="accent1"
                @click="nextAction"
                :icon-right="step == 1 ? 'send' : 'check'"
                :disabled="step == 3 ? false : (!numberValid || loading)"
                >
                {{ nextButtonTitle() }}
                <template #append="{ icon, iconAttributes }">
                    <Icon :name="step == 1 ? 'ic:sharp-send' : 'material-symbols:check-rounded'" style="color: var(--va-accent1);" size="20" class="ml-2 mt-0.5" mode="svg"/>
                </template>
                </VaButton>
            </div>
        </VaInnerLoading>
   </div>
   
       <!-- <div v-else class="flex flex-col border-t-2 border-l-2 border-r-2 border-dashed shadow-lg shadow-neutral-400" style="position:fixed; bottom:0; background-color: var(--va-background-secondary); padding: 4px; padding-top: 8px;  border-color: var(--va-background-border);">
         
           <VaInnerLoading :loading="loading">

           <VaButton
               preset="secondary"
               class="grow mx-2 mb-2"
               style="min-width: 100px;"
               @click="back"
               :icon="step <= 2 ? 'arrow_back_ios' : ''"
               :icon-right="step == 3 ? 'arrow_forward_ios' : ''"
               :disabled="loading"
               >
               {{ backButtonTitle() }}
               </VaButton>
               
            
                <VaButton
                preset="primary"
                class="grow mx-2 mb-2"
                style="min-width: 100px;"
                color="accent1"
                @click="nextAction"
                :icon-right="step == 1 ? 'send' : 'check'"
                :disabled="step == 3 ? false : (!numberValid || loading)"
                >
                {{ nextButtonTitle() }}
                </VaButton>

           
        </VaInnerLoading>
    </div> -->
    </ClientOnly>

    <div class="flex px-10">
        <small class="w-fit text-center mt-3">
            This site is protected by reCAPTCHA and the Google
            <a class="va-link" href="https://policies.google.com/privacy">Privacy Policy</a> and <br/>
            <a class="va-link" href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
    </div>

    <VaModal
        v-model="showConfirmationModal"
        :message="'Do you want to send the OTP to ' + phoneNumber + '?'"
        ok-text="Send"
        :mobile-fullscreen="false"
        size="small"
        @ok="sendOTP"
    />

    </div>
</NuxtLayout>
 </template>
 
 <style>
 
 </style>